import React 					from 'react';
import { DateInput } 	from 'semantic-ui-calendar-react';
import styled					from 'styled-components';
import Field					from 'base/form/field.jsx';

const Date = styled(DateInput)`
	width: 100%;
`;

export default class DateEditField extends React.Component{

	handleChange(event, {name, value}){
		//console.log('date changed', name, value);
  }

	render(){
		const { fieldname, label, placeholder, value } = this.props;
		return (
			<Field
				label={label}
				action={
					<Date
						fluid
			      name={fieldname}
			      placeholder={placeholder}
			      value={value}
			      iconPosition="right"
			      onChange={this.handleChange}
			    />
			  }
			/>
		);
	}
}
