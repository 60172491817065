import Request from 'data/openkb.cloud.js';

const STORAGE_USER_KEY    = 'authenticated-user';
const URL_AUTHENTICATE    = '/User/Authenticate';
const URL_GET_BY_ID       = '/User/Get/';
const URL_GET_BY_GROUP_ID = '/User/GetByGroupID/';
const URL_GET_LISTING     = '/User/Overview/';
const URL_GET_MODULES     = '/User/Modules';
const URL_GET_FAVORITES   = '/User/Favorites/';
const URL_SET_STATUS      = '/User/Status/';
const URL_SIGNUP          = '/User/Signup';

export default class AuthApi{
  
	static authenticate(username, password, onSuccess, onError) {
		//console.log('USER AUTHENTICATE', username, password);
		Request({
			method: 'POST',
			url: URL_AUTHENTICATE,
			data: {
				Username: username,
				Password: password,
			},
		}).then((payload) => {
			//console.log('USER AUTHENTICATE payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static cacheUser(user) {
		//console.log('USER API cacheUser', user);
		localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
	}

	static clearUser() {
		//console.log('clearing cached user');
		localStorage.removeItem(STORAGE_USER_KEY);
	}

	static getCurrent() {
		//console.log('USER API: getCurrent()');
		return JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
	}

	static getByID(id, onSuccess, onError) {
		//console.log('USER getByID', id);
		Request({
			method: 'GET',
			url: URL_GET_BY_ID + id,
		}).then((payload) => {
			//console.log('USER payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static getByGroupID(id, onSuccess, onError) {
		//console.log('USER getByGroupID', id);
		Request({
			method: 'GET',
			url: URL_GET_BY_GROUP_ID + id,
		}).then((payload) => {
			//console.log('USER payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static getFavorites(onSuccess, onError) {
		//console.log('USER getFavorites');
		Request({
			method: 'GET',
			url: URL_GET_FAVORITES,
		}).then((payload) => {
			//console.log('USER payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static getListing(onSuccess, onError) {
		//console.log('USER getListing');
		Request({
			method: 'GET',
			url: URL_GET_LISTING,
		}).then((payload) => {
			//console.log('USER payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static getModules(onSuccess, onError) {
		//console.log('USER getModules');
		Request({
			method: 'GET',
			url: URL_GET_MODULES,
		}).then((payload) => {
			//console.log('USER getModules', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static setStatus(id, status, comments, onSuccess, onError) {
		//console.log('USER getByID', id);
		Request({
			method: 'POST',
			url: URL_SET_STATUS + id,
			data: {
				Status: status,
				StatusDescription: comments,
			},
		}).then((payload) => {
			//console.log('USER payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}

	static signup(user, onSuccess, onError) {
		//console.log('USER SIGNUP', user);
		Request({
			method: 'POST',
			url: URL_SIGNUP,
			data: user,
		}).then((payload) => {
			//console.log('USER SIGNUP payload', payload);
			if (payload && payload.Status === 'Success') {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: 'No payload was returned, or a connection error occurred',
					})
				);
			}
		});
	}
}
