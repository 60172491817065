import React 								from 'react';
import { DatesRangeInput } 	from 'semantic-ui-calendar-react';
import styled								from 'styled-components';
import Field								from 'base/form/field.jsx';

const DateRange = styled(DatesRangeInput)`
	width: 100%;
`;

export default class DateRangeEditField extends React.Component{

	render(){
		const { fieldname, label, placeholder, value, onChange } = this.props;
		return (
			<Field
				label={label}
				action={
					<DateRange
						fluid
			      name={fieldname}
			      placeholder={placeholder}
			      value={value}
			      iconPosition="right"
			      onChange={onChange}
			    />
			  }
			/>
		);
	}
}
