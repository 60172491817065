import React from 'react'
import { Message } from 'semantic-ui-react'

export default function Error(props){
  const { modulename='', view='' } = props;
  console.log('ERROR VIEW', props);
  return (
    <Message negative>
      <Message.Header>NOT FOUND: {modulename}/{view}</Message.Header>
    </Message>
  )
}
