import axios from "axios";

const Request = async (args) => {
	try {
		//console.log("REQUEST");
		let BASE_URL =
		  //"http://alpha.api.openkb.cloud"
		  //"http://beta.api.openkb.cloud"
      "http://api.openkb.cloud"
    ;

		const METHODS = ["GET", "POST", "PUT", "HEAD", "PATCH", "DELETE"];

		// Validate the args
		if (!METHODS.includes(args.method)) {
			throw new Error("Invalid Request Method");
		}

		let config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			method: args.method,
			baseURL: BASE_URL,
			url: `${args.url}`,
			onUploadProgress: (progressEvent) => {
  			//console.log('UPLOAD PROGRESS', progressEvent.loaded)
  		}
		};

		if (args.params) {
			config.params = args.params;
		}

		if (args.auth) {
			config.headers.common["Authorization"] = args.auth;
		}

		if (args.data) {
			config.data = args.data;
		}
		//console.log("REQUEST", config.url);
		if (config.method === "POST") {
			let res = await axios.post(BASE_URL + args.url, config.data, config);
			return res.data;
		} else {
			let res = await axios(config);
			return res.data;
		}
	} catch (e) {
		//console.error(e);
	}
};

export default Request;
