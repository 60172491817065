import React 						from 'react';
import { Input }	from 'semantic-ui-react';
import styled						from 'styled-components';
import Field						from 'base/form/field.jsx';

const EmailInput = styled(Input)`
	width: 100%;
`;

export default class EmailEditField extends React.Component{

	render(){
		const {  label, placeholder } = this.props;
		return (
			<Field
				action={<EmailInput icon='mail' fluid />}
				label={label}
				placeholder={placeholder}
			/>

		);
	}
}
