const theme = {
    primary: '#3498db',
    secondary: '#f1c40f',
    lightest: '#ecf0f1',
    darkest: '#2c3e50',
    success: '#2ecc71',
    warning: '#f1c40f',
    danger: '#e74c3c'
}
export default theme
