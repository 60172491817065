/**
* Wrap react-flexbox-grid and add container responsiveness
*		Making each container render with a width based context, we can make responsive containers
*   meant to be a drop in replacement for Col, Grid, Row
*   if viewport(constraints){
* 		width === large  - sizes={{lg: 3...}}  //TODO finalize these sizes
* 		width === large  - sizes={{lg: 3...}}  //TODO finalize these sizes
* 		width === large  - sizes={{lg: 3...}}  //TODO finalize these sizes
*/

//Libraries
import React                from 'react';
import ContainerDimensions  from 'react-container-dimensions';
import { Col as RFGCol, Grid as RFGGrid, Row as RFGRow }
                            from 'react-flexbox-grid';
import styled               from 'styled-components';

const ColContainer = styled(RFGCol)`
	margin: 0 !important;
	padding: 0 !important;
`;

const GridContainer = styled(RFGGrid)`
	margin: 0 !important;
	padding: 0 !important;
`;

const RowContainer = styled(RFGRow)`
	margin: 0 !important;
	padding: 0 !important;
	padding-top: 5px;
`;


//TODO: These sizes all need to be configurable
//TODO:  JSONIFY THESE VALUES
const getGridWidth = (width, sizes) => {
  //console.log('getGridWidth', width, sizes);
	if(!sizes){
		return null;
	}
	if(width >= 1200){
		return sizes.lg;
	}else if(width >= 900){
		return sizes.md;
	}else if(width >= 500){
		return sizes.sm;
	}else{
		return sizes.xs;
	}
}

/**
* TODO: use of container dimensions might be bad
*/
export const Col = (props) => {
  //console.log('COL RENDER', props);
  return (
    <ContainerDimensions>
  	  { ({ width, height }) =>
    	<ColContainer {...props} xs={getGridWidth(width, props.sizes)}>
    		{props.children}
    	</ColContainer>
    }
  	</ContainerDimensions>
  );
}

/**
* TODO: use of container dimensions might be bad
*/
export const Grid = (props) => {
  return (
  	<GridContainer id="GRID" {...props}>
  	  {props.children}
		</GridContainer>

  );
}

/**
* TODO: use of container dimensions might be bad
*/
export const Row = (props) => {
  return (
  	<RowContainer {...props}>
  		{props.children}
  	</RowContainer>
  );
}
