import React        from 'react';
import { Image, Segment, Tab }
                    from 'semantic-ui-react';
import styled       from 'styled-components';
import logoPath     from '../images/logo.png';
import SignupForm		from './signup.jsx';
import LoginForm		from './login.jsx';
import UserForgot		from './forgot.jsx';

const Body = styled.div`
  margin: 25vh auto;
  max-width: 500px;
  text-align: center;
  height: 100vh;
`;

const Logo = styled(Image)`
  margin: 10px auto;
  width: 350px;
`;

const BodyPanel = styled.div`
  padding-top: 30px;
`;

export default class ThemeAuthPanel extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      show: {
				panel: 'login'
      }
    }

    this.showPanel = this.showPanel.bind(this);
  }
  
  getPanel(panel){
    const { userChanged } = this.props;
    switch(panel){

      case 'forgot':
        return (
          <UserForgot
						cancelUpdate={this.showLogin}
						updateEntity={this.forgotLogin}
						userChanged={this.props.userChanged}
					/>
				);
        
      case 'signup':
        return (
          <SignupForm
            align="center"
						cancelUpdate={this.showLogin}
						updateEntity={this.createAccount}
						userChanged={this.props.userChanged}
					/>
				);
        
      case 'login':
      default:
        return (
				  <LoginForm
				    align="center"
						showSignup={this.showSignup}
						userChanged={userChanged}
          />
        )

    }
  }
  
  showPanel(panel){
    const { show } = this.state;
    show.panel = panel;
    this.setState({
      ...this.state,
      show
    });
  }

  render(){
    const { show } = this.state;
    return (
      <Segment inverted textAlign='center'>
        <Body>
          <Logo src={logoPath} />
  				<BodyPanel>
    				{this.getPanel(show.panel)}
          </BodyPanel>
          
  				<Tab 
  					menu={{ inverted: true, secondary: true, pointing: true }}
  					onTabChange={(e, data) => {
    					//console.log('TAB CHANGE', data);
    					this.showPanel(data.panes[data.active_item].key);
  					}}
  					panes={[
  						{
    						key: 'login',
  							menuItem: 'LOGIN'
  						},
  						{
    						key: 'forgot',
  							menuItem: 'FORGOT CREDENTIALS'
  						},
  						{
    						key: 'signup',
  							menuItem: 'SIGNUP'
  						}
  						]}
  				/>
        </Body>
      </Segment>
    )
  }
}
