import React    from 'react';
import styled		from 'styled-components';
import { Image }
                from 'semantic-ui-react'

const SizedImage = styled(Image)`
	${props => props.height ? 'height: ' + props.height + 'px' : ''};
	${props => props.width ? 'width: ' + props.height + 'px' : ''};
`

export default class ProfileAvatar extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      show: {
        status: false,
        standup: false
      }
    };
  }

  render(){
    const { height=100, width=100, user, onClick } = this.props;
   //console.log('USER PROFILE', user);
    return (
      <SizedImage
      	fluid
      	height={height}
      	width={width}
      	src={user.Avatar} onClick={onClick} />
    );
  }
}
