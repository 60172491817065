import React 	from 'react';
import { Icon, Label, Menu }
							from 'semantic-ui-react';
import styled from 'styled-components';
import Api 		from 'modules/users/api.jsx';

const Title = styled.div`
	margin: 8px 0px;
`;

export default class ThemeSidebarPanel extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			activeItem: '',
			modules: [],
		};

		this.loadModule = this.loadModule.bind(this);
  }

  componentDidMount(){
		this.fetch();
	}

	fetch() {
		Api.getModules((modules) => {
			this.setState({
				...this.state,
				modules,
			});
		});
	}

	hasSearchResults(moduleSlug) {
		//console.log('hasSearchResults', moduleSlug);
		const { search_results=[] } = this.props;
		let items = search_results.filter((item) => {
			return (item.ModuleSlug === moduleSlug)
				? item
				: null;
		});

		return items.length > 0;
	}

	getSearchCount(moduleSlug) {
		const { search_results=[] } = this.props;
		let items = search_results.filter((item) => {
			return (item.ModuleSlug === moduleSlug)
				? item
				: null;
		});

		return items[0] ? items[0].Results.length : 0;
	}

	getSearchLabel(moduleSlug) {
		if (this.hasSearchResults(moduleSlug)) {
			return (
				<Label
					color='red'
					//corner='left'
					fluid
				>
					{this.getSearchCount(moduleSlug)}
				</Label>
			);
		}
	}

	loadModule(module) {
		this.setState({
			...this.state,
			activeItem: module.item
		});
		this.props.loadModule(module);
	}

	showModule(moduleSlug) {
		return true;
	}

	render() {
		const { modules } = this.state;
		const { activeItem, vertical=true } = this.props;
		return (
			<Menu
				fluid
				vertical={vertical}
				inverted
				
			>
				{modules.map((module) => {
					if (this.showModule(module.Slug)) {
						return (
							<Menu.Item
								key={module.ID}
								active={activeItem === module.Slug}
								onClick={() => this.loadModule({
  								modulename: module.Slug
  						  })}
							>
								<Icon name={module.Icon} size='large' />
								<Title>{module.Name}</Title>
							</Menu.Item>
						);
					}else{
  				  return null;
  				}
				})}
			</Menu>
		);
	}
}
