import Request from 'data/openkb.cloud.js';
//import { ipcRenderer } from 'electron';

const EVENT_WORKSTATION_LISTING = 'workstation-listing';
const EVENT_WORKSTATION_PROCESS = 'workstation-process';
const STORAGE_WORKSTATION_KEY 	= "workstation-config";
const URL_ADD_MODULE 						= "/Workstation/AddModule/";
const URL_GET_BY_ID 						= "/Workstation/Get/";
const URL_GET_LISTING 					= "/Workstation/Overview/";
const URL_REMOVE_MODULE 				= "/Workstation/RemoveModule/";

export default class WorkstationApi{

	//WIP - broadcast a message to the network to be processed (or not)
	static broadcastMessage(payload, onSuccess){
		console.log("WORKSTATION sendWorkstationMessage", payload);
/*
		ipcRenderer.on(EVENT_WORKSTATION_PROCESS, (event, result) => {

	    return onSuccess(result);
	  });
	  ipcRenderer.send(EVENT_WORKSTATION_PROCESS, payload);
*/
	}


	static addModule(id, moduleID, onSuccess, onError) {
		//console.log("WORKSTATION addModule", id, moduleID);
		Request({
			method: "POST",
			url: URL_ADD_MODULE + id,
		}).then((payload) => {
			//console.log('WORKSTATION payload', payload);
			if (payload && payload.Status === "Success") {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: "No payload was returned, or a connection error occurred",
					})
				);
			}
		});
	}

	static cacheWorkstation(user) {
		//console.log("WORKSTATION API cacheWorkstation", user);
		localStorage.setItem(STORAGE_WORKSTATION_KEY, JSON.stringify(user));
	}

	static getCurrent(onSuccess) {
		//console.log("WORKSTATION API: getCurrent()");
		return JSON.parse(localStorage.getItem(STORAGE_WORKSTATION_KEY));
	}

	static getByID(id, onSuccess, onError) {
		//console.log('WORKSTATION getByID', id);
		Request({
			method: "GET",
			url: URL_GET_BY_ID + id,
		}).then((payload) => {
			//console.log('WORKSTATION payload', payload);
			if (payload && payload.Status === "Success") {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: "No payload was returned, or a connection error occurred",
					})
				);
			}
		});
	}

	static getLocal(onSuccess, onError) {
		//console.log("WORKSTATION getListing");
/*
		ipcRenderer.on('workstation-listing', (event, workstations) => {
	    console.log('WORKSTATION MESSAGE', workstations);
	    //workstations[workstation.Slug] = workstation;
	    //setWorkstations(workstations);

	    return onSuccess(workstations);
	  });
	  ipcRenderer.send('workstation-listing');
*/
	}

	static getListing(onSuccess, onError) {
		//console.log("WORKSTATION getListing");
		Request({
  		cache: {
        key: 'Workstations'
      },
			method: "GET",
			url: URL_GET_LISTING,
		}).then((payload) => {
			//console.log('WORKSTATION payload', payload);
			if (payload && payload.Status === "Success") {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: "No payload was returned, or a connection error occurred",
					})
				);
			}
		});
	}

	static removeModule(id, moduleID, onSuccess, onError) {
		//console.log("WORKSTATION addModule", id, moduleID);
		Request({
			method: "POST",
			url: URL_REMOVE_MODULE + id,
		}).then((payload) => {
			//console.log('WORKSTATION payload', payload);
			if (payload && payload.Status === "Success") {
				return onSuccess(payload.Data);
			} else {
				return (
					onError &&
					onError({
						message: "No payload was returned, or a connection error occurred",
					})
				);
			}
		});
	}
}
