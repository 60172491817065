import React, { lazy, Suspense } from 'react';
import NotFound from './defaultviews/notfound.jsx';

import modules from '.modules.json';

export default class BaseModule extends React.Component{

  constructor(props){

    super(props);
    this.state = {
      BaseComponent: null
    };
    //console.log('MODULE/BASE::CONSTRUCT', props);
  }

  componentDidMount(){
    const { modulename='', view='' } = this.props;
    //console.log('MODULE/BASE::DIDMOUNT', modulename, view);
    this.fetch(modulename, view);
  }

  componentDidUpdate(prevProps){
    //console.log('MODULE/BASE::componentDidUpdate', prevProps);
    const { view='' } = this.props;
    if(prevProps.modulename && (this.props.modulename != prevProps.modulename)){
      this.fetch(this.props.modulename, view);
    }
  }

  getModulePath(modulename, view){
    //console.log('MODULE/BASE::getModulePath: ', modulename, view);
    if(modules.hasOwnProperty(modulename)){
      const { handlers={} } = modules[modulename];
      const  viewHandler  = handlers[view];

      //console.log('Handlers', handlers);
      //console.log('ViewHandler', viewHandler);
      if(viewHandler){
        return viewHandler;
      }
  	}
  }

  fetch(modulename, view, reportErrors=false){
    const modulePath = this.getModulePath(modulename, view);
    //console.log('MODULE/BASE::fetch', modulename, view);


    //WARNING: it might be tempting to remove that 'modules/' portion of that path
    //  but it is fundamentally vital that import() knows how to use this, apparently
    //  This is definitely a CODESMELL
    if(modulePath){
      this.setState({
        ...this.state,
        BaseComponent: lazy(() => import('modules/' + modulePath))
      });
    }else{

      //Throw exception if requested
      if(reportErrors){
        throw new Error('Module handler not found for module/view: ' + modulename + '/' + view);
      }

      this.setState({
        ...this.state,
        BaseComponent: lazy(() => import('base/module/defaultviews/notfound.jsx'))
      });


    }
  }

  render(){
    //const { config={}, id='', item={}, slug='', loadModule, view='' } = this.props;
    //console.log('MODULE/BASE::render', this.props);
    const { BaseComponent } = this.state;
		return (
  		<Suspense fallback={<div>Page is Loading...</div>}>
  		  {BaseComponent && React.createElement(BaseComponent, this.props)}
      </Suspense>
		);
	}
}
