import React 	from 'react';
import moment	from 'moment';

export default class TimeFormat extends React.Component{

  render() {
	  const { children, format='hh:mm:ss.ms' } = this.props;
	  const milliseconds = children || 0;
	  const seconds = children/1000;
	  const minutes = children/1000/60;
	  const hours = children/1000/60/60;
	  let unit = '';
	  let value = '';
	  if(seconds < 1){
		  unit = 'ms';
		  value = Math.round(milliseconds * 1000, 2);
		}else if(minutes < 1){
		  unit = 's';
		  value = seconds.toFixed(3);
		}else if(hours < 1){
		  unit = 'm';
		  value = minutes.toFixed(3);
	  }else{
		  unit = 'h';
		  value = hours.toFixed(3);
		}

//	  //console.log('TIME', this.props.children)
    return (
      <React.Fragment>
      	{value} {unit}
      </React.Fragment>
    );
  }
}
