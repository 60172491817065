import React 				from 'react';
import { Form, Label, Segment, TextArea } from 'semantic-ui-react';

export default class TextEditField extends React.Component{

	render(){
		const { error, fieldname, placeholder, value } = this.props;
		return (
			<Form>
				<Segment>
					<Label attached='top' size="large">{fieldname}</Label>
					<TextArea
						placeholder={placeholder}
						defaultValue={value}
						error={error}
					/>
				</Segment>
			</Form>
		);
	}
}
