import React 						from 'react';
import Field 						from 'base/form/field.jsx';

export default class BytesEditField extends React.Component{

	render(){
		const { label, placeholder } = this.props;
		return (
			<Field
				action={<input />}
				label={label}
				placeholder={placeholder}
				unit='bytes'
			/>
		);
	}
}
