import React from 'react';
import Field from 'base/form/field.jsx';

export default class PasswordEditField extends React.Component{

	render(){
		const { error, icon, label, onChange, placeholder, unit, value } = this.props;
		return (
			<Field
				action={<input />}
				icon={icon}
				label={label}
				placeholder={placeholder}
				unit={unit}
				value={value}
				onChange={(value) => onChange(value)}
				error={error}
				type="password"
			/>
		);
	}
}
