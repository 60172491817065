import React from 'react';
import Field from 'base/form/field.jsx';
import Form  from 'base/form/panel.jsx';

export default class ThemeAuthForgotForm extends React.Component{

	constructor(props){
		super(props);
		this.state = {
      errors: {}
		};
	}

	render(){
  	const { cancelUpdate, updateEntity } = this.props;
  	const { errors } = this.state;

		return (
			<Form
				cancelUpdate={cancelUpdate}
				updateEntity={updateEntity}
				errors={errors}
				saveLabel="RESET PASSWORD"
				fields={{
					EmailAddress: {
						label: 'Email',
						type: 'String'
					}
				}}
			/>
		);
	}
}
