import React from 'react';
import Field from 'base/form/field.jsx';

export default class ScheduleEditField extends React.Component{

	render(){
		const { label, placeholder, unit } = this.props;
		return (
			<Field
				action={<input />}
				label={label}
				placeholder={placeholder}
				unit={unit}
			/>
		);
	}
}
