import React 			from 'react';
import { Input }	from 'semantic-ui-react';
import styled			from 'styled-components';
import Field			from 'base/form/field.jsx';

const PhoneInput = styled(Input)`
	width: 100%;
`;

export default class PhoneEditField extends React.Component{

	render(){
		const { label, placeholder } = this.props;
		return (
			<Field
				action={<PhoneInput icon='phone' fluid />}
				label={label}
				placeholder={placeholder}
			/>

		);
	}
}
