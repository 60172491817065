//import '@blueprintjs/core/lib/css/blueprint.css';
//import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'semantic-ui-css/semantic.min.css';
import 'normalize.css/normalize.css';

import React          from 'react';
import ReactDom       from 'react-dom';
import Api            from './base/api/prefetch.jsx';
import Layout         from './theme/layout.jsx';
import config         from './base/configuration.jsx';
import UserApi        from 'modules/user/api.jsx';
import WorkstationApi from 'modules/workstation/api.jsx';



//**************************************************************
const DEFAULT_MODULE 	= 'Finances';
const DEFAULT_ITEM		= 0; //325;
//**************************************************************

//**************************************************************
//Deep Debugging
const DEBUG_DEMO_ENABLED = 0;			//bool
const DEBUG_DEMO_TIMEOUT = 5000; //MILLISECONDS	- (Demo Pulserate)
const DEBUG_DEMO_MODE // [module, item]
= 'module'; 
//= 'item';
//**************************************************************


export default class App extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      data: {

      },
      demo: {
        index: 0
      },
      module: {
        modulename: DEFAULT_MODULE,
        id: DEFAULT_ITEM
      },
      modules: [],
  		search_results: [],
  		show: {
    		loader: false
      },
			user: null,
			workstation: null
    };

    this.demoMode = this.demoMode.bind(this);
    this.fetchModules = this.fetchModules.bind(this);
    this.initWorkstationProcessListener = this.initWorkstationProcessListener.bind(this);
    this.loadModule = this.loadModule.bind(this);
    this.moduleDataChanged = this.moduleDataChanged.bind(this);
		this.searchResultsChanged = this.searchResultsChanged.bind(this);
		this.userChanged = this.userChanged.bind(this);
  }

  componentDidMount(){
    this.fetchModules();
    this.setState({
      ...this.state,
      user: UserApi.getCurrent(),
			workstation: WorkstationApi.getCurrent()
		});

		//Init listeners
		//this.initWorkstationProcessListener();

    if(DEBUG_DEMO_ENABLED){
  		this.demoMode(DEBUG_DEMO_TIMEOUT);
    }
  }

  initWorkstationProcessListener(){
/*
	TODO: disabled for now
		ipcRenderer.on('workstation-load-module', (event, args) => {
	    console.log('WORKSTATION LOAD MODULE', args);
	    this.loadModule(args);
	  })
*/
	}

  demoMode(timeout){
  	this.demoTimer = setInterval(() => {
  		const { demo, module, modules } = this.state;

  		if(modules.length > 0){
    		demo.index = demo.index < modules.length - 1 ? demo.index + 1 : 0;
    		let nextModule = this.getModuleByIndex(demo.index);
    		console.log('NEXT MODULE:', nextModule);

        switch(DEBUG_DEMO_MODE){
          case 'module':
            module.modulename = nextModule.Slug;
            break;

          case 'item':
            //module.id = Math.ceil(Math.random() * 100);
        		module.id = (module.id < 100 ? module.id + 1 : 1);

        }

        //console.log('DEMO MODE: ', module);
    		this.loadModule(module);
    		this.setState({
    			...this.state,
    			demo: demo,
    			module: module
    		});
      }
  	}, timeout);
  }

  fetchModules() {
		UserApi.getModules((modules) => {
			this.setState({
				...this.state,
				modules: modules,
/*
	TODO: make this optional
				module: {
	        modulename: modules[0].Slug,
	        id: DEFAULT_ITEM
	      }
*/
			});
		});
	}

	getModuleByIndex(index) {
		const { modules } = this.state;
		return modules[index];
	}

	getModuleBySlug(moduleSlug) {
		const { modules } = this.state;
		let filteredModules = modules.filter((item) => {
			return item.Slug === moduleSlug;
		});

		if (filteredModules.length) {
			return filteredModules[0];
		}
	}

  loadModule(module) {
		//console.log('----------------------------------------------------------------------------------------------------------------');
		//console.log('MODULE CHANGED: ', module);
		this.setState({
			module: module
		});
	}

	moduleDataChanged(module){
  	const { data } = this.state;
  	data[module.modulename] = module.items;
    this.setState({
      ...this.state,
      data
    });
  }

  searchResultsChanged(search_results) {
		//console.log('searchResults:', search_results);
		this.setState({
			...this.state,
			search_results: search_results,
		});
	}

  userChanged(user) {
    this.fetchModules();
		this.setState({
			...this.state,
			user: user,
		});
	}

  render(){

    const { module, search_results, show, user } = this.state;

    return (
      <React.Fragment>
        {show.loader &&
        <Api
          onData={this.setModuleData}
        />
        }
        <Layout
          config={config}
          loadModule={this.loadModule}
          modulename={module.modulename}
          id={module.id}
          item={module.item}
          search_results={search_results}
          searchResultsChanged={this.searchResultsChanged}
          user={user}
          userChanged={this.userChanged}
        />
      </React.Fragment>
    );
  }
}
