import React 						from 'react';
import { Checkbox, Input }	from 'semantic-ui-react';
import styled from 'styled-components';
import Field from '../field.jsx';

const CheckboxContainer = styled.div`
  padding: 5px 10px;
`;

export default class CheckboxSliderEditField extends React.Component{

  render(){
    const { label, value, onChange } = this.props;
    return (
      <Field
				action={
  				<Input
            fluid
          >
            <CheckboxContainer>
              <Checkbox
                onChange={(e, { checked }) => onChange(checked)}
                checked={!!value}
                label={label}
                toggle
              />
            </CheckboxContainer>
          </Input>
        }

			/>

    );
  }
}
