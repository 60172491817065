import React from 'react';
import { Input, Label } 		from 'semantic-ui-react';
import styled 							from 'styled-components';

const LabelContainer = styled(Label)`
	width: 10em;
`;

const ErrorContainer = styled(Label)`
	width: 15em;
`;

const InputContainer = styled.span`
`;

export default class EditField extends React.Component{

	static Checkbox       = 'Checkbox';
	static CheckboxSlider = 'CheckboxSlider';
	static Bytes				  = 'Bytes';
	static Currency			  = 'Currency';
	static Date				    = 'Date';
	static DateRange		  = 'Daterange';
	static Email				  = 'Email';
	static Float				  = 'Float';
	static Integer			  = 'Integer';
	static IPAddress		  = 'IPAddress';
	static Password			  = 'Password';
	static Rating         = 'Rating';
	static Phone				  = 'Phone';
	static String				  = 'String';
	static Tag            = 'Tag';
	static Text					  = 'Text';
	static Time					  = 'Time';

	constructor(props){
		super(props);
		this.state = {};
	}

	static Input(props){
		return (
			<InputContainer>{props.children}</InputContainer>
		);
	}

	static Label(props){
		return (
			<LabelContainer size="medium">{props.children}</LabelContainer>
		);
	}

	render(){
		const { error, label, placeholder, type, value, onChange } = this.props;
		return (
  		<React.Fragment>
  			<Input
  				fluid
  				labelPosition={this.props.unit ? 'right' : 'left'}
  				defaultValue={value}
  				onBlur={(e) => {
  					//console.log('BASE FIELD changed: ', e.target.value);
  					onChange(e.target.value)
  				}}
  				placeholder={placeholder}
  				error={error && error.hasOwnProperty('Message')}
  				type={type}
  			>
          {error
        		? <ErrorContainer color='red'>{error.Message}</ErrorContainer>
        		: (label && <LabelContainer size="medium">{label}</LabelContainer>)
          }

    			{this.props.action}

    			{ this.props.unit &&
    			  this.props.unit
    			}
  			</Input>
  		</React.Fragment>
		);
	}
}
