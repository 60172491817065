import React    					from 'react';
import styled 						from 'styled-components';
import { Card, Item, Label, Statistic }
													from 'semantic-ui-react';
import Module   					from 'base/module/summary.jsx';
import SearchStatistics   from 'base/module/search.statistics.jsx';
import Time 							from 'base/format/time.jsx';


const CardContainer = styled(Card.Group)`
	padding: 0 0 75px 25px;
`;

const StatisticsContainer = styled.div`
	padding: 20px 0 0 0;
`;


export default class SearchListing extends React.Component{

	render() {
		const { items=[], loadModule } = this.props;
		return (
			<CardContainer>
				{items.map((module) =>
        <Card fluid>

          <Card.Content>
          	<Label size="huge" ribbon fluid
          		onClick={(e) => loadModule({modulename: module.ModuleSlug, item: { ID: null}})}
          	>
          		{module.Modulename + ' (' + module.Results.length + ')'} - <Time >{module.SearchTime}</Time>
          	</Label>
          	<StatisticsContainer>
							<SearchStatistics
								modulename={module.ModuleSlug}
								items={module.Results}
							/>
						</StatisticsContainer>
          </Card.Content>
          <Card.Content>
            <Item.Group divided>
  						{module.Results.map((item) =>
              <Module
                item={item}
                modulename={module.ModuleSlug}
                onClick={()=> loadModule({
                  modulename: module.ModuleSlug,
                  item: item
                })}
              />
    				  )}
    				</Item.Group>
  				</Card.Content>
        </Card>
        )}
      </CardContainer>
		);
	}
}
