import React      from 'react'
import { Rating } from 'semantic-ui-react'
import styled     from 'styled-components';
import Field			from 'base/form/field.jsx';


const RatingContainer = styled(Rating)`
  margin-left: 25px;
`;

export default class RatingEditField extends React.Component{

  render(){
    const { label, placeholder, onChange } = this.props;
    return (
      <Field
				action={
  				<RatingContainer
  				  maxRating={5} clearable size='massive' icon='star'
  				  onRate={(e, { rating, maxRating }) => onChange(rating)}
  				/>}
				label={label}
				placeholder={placeholder}
			/>
    );
  }
}
