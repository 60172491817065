import React 								from 'react';
import { Grid, Row, Col }   from 'base/grid.jsx';
import { Button, Card, Dimmer, Icon, Loader, Segment }
														from 'semantic-ui-react';
import styled               from 'styled-components';

//Base Fields
import BytesField 					from './field/bytes.jsx';
import CheckboxField 				from './field/checkbox.jsx';
import CheckboxSliderField 	from './field/checkboxslider.jsx';
import CurrencyField 				from './field/currency.jsx';
import DateField 						from './field/date.jsx';
import DateRangeField 			from './field/daterange.jsx';
import EmailField 					from './field/email.jsx';
import Field								from './field.jsx';
import FileUpload						from './field/fileupload.jsx';
import FloatField 					from './field/float.jsx';
import IntegerField 				from './field/integer.jsx';
import PasswordField 				from './field/password.jsx';
import PhoneField 					from './field/phone.jsx';
import RatingField 				  from './field/rating.jsx';
import ScheduleField 				from './field/schedule.jsx';
import StringField 					from './field/string.jsx';
import TextField		 				from './field/text.jsx';
import TimeField		 				from './field/time.jsx';

//Handle Plugins
import Module               from 'base/module/field.jsx';


//Local styles
const FieldContainer = styled(Row)`

`;

const GridContainer = styled(Card)`
	padding-left: 0px;
	padding-right: 0px;
`;

export default class EditForm extends React.Component{

	constructor(props){
		super(props);
		//console.log('BASE FORM CREATE', props);
		this.state = {
			data: props.data || {},
			errors: props.errors || {},
			fields: props.fields,
			isLoading: false
		}

		this.updateEntity = this.updateEntity.bind(this);
	}

	fields(fields, config, errors){
		//console.log('FORM fields()', fields, errors);
		let items = [];
		Object.keys(fields).map(fieldname => {

			let options = fields[fieldname];
			options.error = errors[fieldname];

			items.push(
				<FieldContainer key={fieldname} >
					<Col sizes={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
						{ this.getFieldInput(fieldname, options) }
					</Col>
				</FieldContainer>
			);
			return null;
		});

		return items;

	}

	fieldChanged(fieldname, value){

		const { data, fields } = this.state;
		//console.log('BASE FORM field changed: ' + fieldname, value, data, fields);
		data[fieldname] = value;
		fields[fieldname].edited = value;

		this.setState({
			...this.state,
			data,
			fields
		});
	}

	getFieldLabel(fieldname){
		return ((this.props.fields && this.props.fields[fieldname] && this.props.fields[fieldname].label)
			? this.props.fields[fieldname].label
			: fieldname
		);
	}

	getFieldModule(fieldname){
		return (
			this.props.fields
			&& this.props.fields[fieldname]
			&& this.props.fields[fieldname].modulename
		);
	}

	getFieldPlaceholder(fieldname){
		return (
			this.props.fields
			&& this.props.fields[fieldname]
			&& this.props.fields[fieldname].placeholder
		);
	}

	getFieldUnit(fieldname){
		return (
			this.props.fields
			&& this.props.fields[fieldname]
			&& this.props.fields[fieldname].unit
		);
	}

	getFieldValue(fieldname){
  	const { data={} } = this.props;
		//console.log('getFieldValue:', fieldname, this.props.data);
		return data.hasOwnProperty(fieldname)
			? data[fieldname]
			: ''
		;
	}

	getFieldInput(fieldname, options){
		//console.log('getFieldInput:', fieldname, options);
		switch(options.type){

			case 'Checkbox':
				return (
					<CheckboxField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'CheckboxSlider':
				return (
					<CheckboxSliderField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Bytes':
				return (
					<BytesField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Currency':
				return (
					<CurrencyField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Date':
				return (
					<DateField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'DateRange':
				return (
					<DateRangeField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Email':
				return (
					<EmailField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

      case 'FileUpload':
				return (
					<FileUpload
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Float':
				return (
					<FloatField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Integer':
				return (
					<IntegerField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Password':
				return (
					<PasswordField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Phone':
				return (
					<PhoneField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

      case 'Rating':
				return (
					<RatingField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Schedule':
				return (
					<ScheduleField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'String':
				return (
					<StringField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Text':
				return (
					<TextField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

			case 'Time':
				return (
					<TimeField
					  error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
						options={options}
						onChange={(value)=> this.fieldChanged(fieldname, value)}
					/>);

      default:
        //console.log('OPTIONS', options);
        return (
          <Module
            error={options.error}
						fieldname={fieldname}
						label={this.getFieldLabel(fieldname)}
            modulename={options.type}
            onChange={(value)=> this.fieldChanged(fieldname, value)}
            options={options.options}
						placeholder={this.getFieldPlaceholder(fieldname)}
						unit={this.getFieldUnit(fieldname)}
						value={this.getFieldValue(fieldname)}
          />
        );
		}
	}

  buildPayload(){
    //Build payload
		const { data, fields } = this.state;
		//console.log('FIELDS', fields);
		let payload = {};
		Object.keys(fields).map(item => {
			//console.log(item);
			payload[item] = data[item] || '';
			return null;
		});

		return payload;
  }

	updateEntity(){

		//console.log('FORM updateEntity');
  	this.setState({
    	...this.state,
    	isLoading: true
    });
    let payload = this.buildPayload();


		this.props.updateEntity(payload,
		  (success) => {
  		 	console.log('SAVE FORM SUCCESS: ', success);
      	this.setState({
        	...this.state,
        	isLoading: false
        });

  		},
			(errors) => {
      	console.log('SAVE FORM ERRORS', errors);
				this.setState({
					...this.state,
					errors,
					isLoading: false
				});
			}
		);
	}

	render(){
		const { align='left', errors={}, fields={}, cancelUpdate=null, updateEntity, saveLabel="SAVE", saveLoading='' } = this.props;
		const { isLoading } = this.state;
		//console.log('BASE FORM ERRORS: ', errors);
		const config = {
  		align: align
    };

		return (
			<GridContainer fluid>
				{ this.fields(fields, config, errors) }
				<FieldContainer>
					<Col sizes={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
						<Button.Group fluid>
						{ cancelUpdate && <Button onClick={cancelUpdate}>Cancel</Button>}
						{ updateEntity &&
  						<Button
  						  positive
  						  onClick={this.updateEntity}
  						>
  						  {isLoading
	  						?	<React.Fragment>
	  								<Icon loading name='spinner' />{saveLabel}
	  							</React.Fragment>
		            : saveLabel
                }
  						</Button>
  				  }
						</Button.Group>
					</Col>
				</FieldContainer>
			</GridContainer>
		);
	}
}
