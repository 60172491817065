import axios from "axios";
import Cache from 'base/cache/engine.jsx';

//CORE API WIP - THIS IS UNTESTED
var RestApi = {
  
  fetchCache: async function(cache){
    let data = Cache.get(cache.key);
  	if(data){
  		//console.log('RETURNING CACHED DATA:', data);
  	  return data;
    }
  },
  
  fetchData: async function(config){
    //Get from Cache first
    //TODO:  FETCH    
    let data = (
      this.fetchCache(config)
      || this.getUrl(config)
    );
  },
  
  getUrl: async function(config){
    
    //Then return from API
    return await axios(config);
  },
  
  fetchAndCache: async function(config){
    
  },

  DELETE: function(config){
    const { cache={}, onError, onSuccess, url } = config;
    config.method = 'DELETE';
    return this.fetchData(config);
  },

  GET: function(config){
    const { cache={}, onError, onSuccess, url } = config;
    config.method = 'GET';
    return this.fetchData(config);
  },
  
  PATCH: function(config){
    const { cache={}, onError, onSuccess, url } = config;
    config.method = 'PATCH';
    return this.fetchData(config);
  },


  POST: function(config){
    const { cache={}, onError, onSuccess, url } = config;
    config.method = 'POST';
    return this.fetchData(config);
  },

  PUT: function(config){
    const { cache={}, onError, onSuccess, url } = config;
    config.method = 'PUT';
    return this.fetchData(config);
  }

}


const ConfigureRequest = (args) => {

  // Validate args
	if (!METHODS.includes(args.method)) {
		throw new Error("Invalid Request Method");
	}

	let config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		method: args.method,
		//baseURL: BASE_URL,
		url: `${args.url}`,
		onUploadProgress: (progressEvent) => {
			//console.log('UPLOAD PROGRESS', progressEvent.loaded)
		}
	};

	if (args.params) {
		config.params = args.params;
	}

	if (args.auth) {
		config.headers.common["Authorization"] = args.auth;
	}

	if (args.data) {
		config.data = args.data;
	}
}

const METHODS = [
  "GET", "POST", "PUT", "HEAD", "PATCH", "DELETE"
];



const Request = async (args) => {
	try {
		//console.log("REQUEST", args);
		
		//Check for cached data to return first
		const { cache={} } = args;
		if(cache.key){
  		//console.log('FETCHING CACHED:', cache);
  		let data = RestApi.FetchCache(cache);
  		if(data){
    		//console.log('RETURNING CACHED DATA:', data);
    	  return data;
      }
    }
    
    //Configure request parameters
    let config = ConfigureRequest(args);
		//console.log("FETCHING", config);
		
		//Perform request
		let data = await RestApi.FetchData(config);
		
		//Cache data
    if(cache.key){
  	  //console.log('CACHING?', args);
  	  Cache.set(cache.key, data);
    }
    
    //Return response data
		return data;
		
	} catch (e) {
		//console.error(e);
	}
};


export default RestApi;