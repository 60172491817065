import React, { lazy } from 'react';
import styled from 'styled-components';
import modules from '.modules.json';
import { Header, Icon, Statistic, Message, Progress } from 'semantic-ui-react';

const Status = styled.span`
  font-size: 8px;
  margin: 0;
  padding: 0;
`;

export default class APIPreloader extends React.Component{

  constructor(props){
   //console.log('API: Preloader Init');
    super(props);
    this.state = {
      message: 'API Monitor',
      modules: modules
    }

    this.setMessage = this.setMessage.bind(this);
    this.getPercentComplete = this.getPercentComplete.bind(this);
    this.setPercentComplete = this.setPercentComplete.bind(this);
  }

  componentDidMount(){
    this.fetchAll();
  }

  getModulePath(moduleSlug, view){
    //console.log('getModulePath: ', moduleSlug, view, modules);
    if(
      modules.hasOwnProperty(moduleSlug)
      && modules[moduleSlug].hasOwnProperty('handlers')
      && modules[moduleSlug].handlers.hasOwnProperty(view)
    ){
      return modules[moduleSlug].handlers[view];
  	}else{
      //throw 'Module handler not found for module/view: ' + moduleSlug + '/' + view
    }
  }

  async fetchModuleData(slug){

    let modulePath = this.getModulePath(slug, 'prefetch');
    if(modulePath){
      this.setMessage(slug, 'fetching');
      //console.log('API: Creating API Module', 'modules/' + modulePath);

      const { prefetch } = lazy(() => import('modules/' + modulePath));
      //const prefetch = await import('modules/' + modulePath);
     //console.log('prefetch:', prefetch);
      prefetch(
        (items) => {
          this.props.moduleDataChanged(slug, items);
          this.setPercentComplete(slug, 100);
        }
      );
    }else{
      this.setPercentComplete(slug, 100);
    }
  }

  fetchAll(){
   //console.log('API: starting preload cache...')
    Object.keys(modules).map(slug => {
     //console.log('API: fetching module data: ', slug);
      //this.fetchModuleData(slug);
      this.setMessage(slug, 'fetching');
      setTimeout(
        () => {this.setPercentComplete(slug, 15)},
        this.getRandomIntInclusive(2000,10000)
      );
      return null;
    });
  }

  getColor(slug){
    return this.state.modules[slug].complete
    ? 'green'
    : 'grey'
    ;
  }

  getRandomIntInclusive(min, max){
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  getPercentComplete(slug){
    if(this.state.modules.hasOwnProperty(slug)){
      //DEBUG return this.state.modules[slug]
    }
    return this.getRandomIntInclusive(1, 100);
  }

  getTotalPercentComplete(){
    return this.getRandomIntInclusive(1, 100);
  }

  isComplete(slug){
    return (
      this.state.modules.hasOwnProperty(slug)
      && this.state.modules[slug].hasOwnProperty('complete')
    );
  }

  setPercentComplete(slug, percent){
   //console.log('setPercentComplete', slug, percent);
    const { modules } = this.state;
    modules[slug].complete = percent;

    this.setState({
      ...this.state,
      modules
    });
  }

  setMessage(slug, message){
   //console.log('setPercentComplete', slug);
    const { modules } = this.state;
    modules[slug].message = message;
    this.setState({
      ...this.state,
      modules
    });
  }

  render(){
    const totalPercentComplete = this.getTotalPercentComplete();
    const { message, modules } = this.state;
    //console.log('API: Percent Complete', totalPercentComplete);
    return (
      <Message>
        <Header>{message}</Header>
        <Progress
          indicating
          percent={totalPercentComplete}
          progress
        />
        <Statistic.Group>
        {Object.keys(modules).map(slug =>
        <Statistic
          color={this.getColor(slug)}
          key={slug}
        >
          <Statistic.Label>
            {(modules[slug].complete >= 100) &&
            <Icon name="check circle" />
            }

            {slug}: <Status>{modules[slug].message}</Status>
            <Progress
              size="tiny"
              percent={this.getPercentComplete(slug)}
              progress
              indicating
            />
          </Statistic.Label>
          <Statistic.Value>

          </Statistic.Value>
        </Statistic>
        )}
        </Statistic.Group>
      </Message>
    );
  }
}
