import React 		from 'react';
import Field		from 'base/form/field.jsx';
import Form 		from 'base/form/panel.jsx';
import UserApi	from 'modules/auth/api.jsx';

export default class ThemeAuthSignupForm extends React.Component{

	constructor(props){
		super(props);
		this.state = {
      errors: {}
		};
		this.updateEntity = this.updateEntity.bind(this);
		this.onFailure  = this.onFailure.bind(this);
		this.onSuccess  = this.onSuccess.bind(this);
	}

	updateEntity(user){
		UserApi.signup(user,
			(user) => {this.onSuccess(user);},
			(errors) => {this.onFailure(errors);}
		);
	}

	onFailure(errors){
		this.setState({
			...this.state,
			errors: errors
		});
	}

	onSuccess(user){
  	const { userChanged } = this.props;
		UserApi.cacheUser(user);
		this.setState({
			...this.state,
			user: user
		});

		//notify listeners
		userChanged(user);
	}

	render(){
		const { cancelUpdate } = this.props;
		const { errors } = this.state;

		return (
			<Form
				cancelUpdate={cancelUpdate}
				updateEntity={this.updateEntity}
				errors={errors}
				saveLabel="CREATE ACCOUNT"
				data={this.props.user} fields={{
					FirstName: {
						label: 'First Name',
						type: 'String'
					},
					LastName: {
						label: 'Last Name',
						type: 'String'
					},
					EmailAddress: {
						label: 'Email',
						type: 'String'
					},
					Password: {
						type: 'String'
					}
				}}
			/>
		);
	}
}
