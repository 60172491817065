import React 	from 'react';
import Field	from 'base/form/field.jsx';
import Form   from 'base/form/panel.jsx';
import Api 	  from 'modules/users/api.jsx';

export default class UserSignupForm extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			errors: [],
			user: {
        Username: '',
        Password: ''
			}
		};

		this.login = this.login.bind(this);
	}

	login(user, onSuccess, onErrors){
		//console.log('LOGIN:', user);
		const { userChanged } = this.props;
		Api.authenticate(user.Username, user.Password,
			(user) => {
				Api.cacheUser(user);
				this.setState({
					...this.state,
					user: user
				});
				userChanged(user);
				onSuccess(user);
			},
			(errors) => {
				this.setState({
					...this.state,
					errors: errors
				});
				onErrors(errors);
			}
		);
	}

	render(){
		const { user, errors } = this.state;
		return (
			<Form
			  align="center"
			  data={user}
				updateEntity={this.login}
				saveLabel="LOGIN"
				saveLoading="Logging you in..."
				errors={errors}
				fields={{
					Username: {
						label: 'Username',
						type: 'String'
					},
					Password: {
						type: 'Password'
					}
				}}
			/>
		);
	}
}
