/**
* This is the main layout of containers and base data loading and handling
*/
import './layout.css';
import styled, { ThemeProvider }
                      from 'styled-components';
import { Image, Input, Menu, Ref, Segment, Sidebar, Sticky }
                      from 'semantic-ui-react'
import React, { createRef }
                      from 'react';
import { Col, Grid, Row } from 'base/grid.jsx';
import BodyContent    from './content.jsx';
import AuthPanel      from './auth/panel.jsx';
import Theme          from './theme.jsx';
import Modules     		from './sidebar/panel.jsx';
import ProfileAvatar  from 'modules/profile/avatar.jsx';
import SearchListing  from 'modules/search/listing.jsx';

const HorizontalAvatarContainer = styled.span`
	float: left;
	clear: left;
  margin: 0px;
  height: 20px;
  width: 20px;
`;

const HoriztonalModules = styled.span`
	float: left;
	clear: none;
	height: 4em;
	overflow-y: scroll;
'`;

const LeftCol = styled(Col)`
	height: 100vh;
	overflow-y: scroll;
	padding-bottom: 20px;
`;

const Menubar = styled(Segment)`
	height: 100%;

`

const RightCol = styled(Col)`
	height: 98vh;
//	overflow: scroll;
	padding: 10px !important;
`;

const HorizontalCol = styled(Col)`
	height: 100vh;
	overflow-y: scroll;
	padding: 30px !important;
`;

const HorizontalLayout = (props) => {
  const { activeModule, id='', innerRef, item, loadModule, modulename, search_results=[], searchResultsChanged, user, userChanged } = props;
  return (
    <Grid fluid>
			<Row>
				<Col sizes={{lg: 12}}>
					<Segment inverted>
	          <HorizontalAvatarContainer>
	            <ProfileAvatar
	            	height={50}
	            	width={50}
	      				onClick={() => loadModule({modulename: 'Search'})}
	      				user={user}
	      				userChanged={userChanged}
	      				userLoggedOut={() => userChanged(null)}
	      			/>
	          </HorizontalAvatarContainer>
						<HoriztonalModules
						//	align="right"
						>
		          {!!search_results.length &&
		          <SearchListing
		  					items={search_results}
		  					loadModule={loadModule}
		  				/>
		  				}

		          <Modules
		          	vertical={true}
		    				loadModule={loadModule}
		    				activeItem={modulename}
		    				search_results={search_results}
		    			/>
		    		</HoriztonalModules>
	    		</Segment>
				</Col>
			</Row>
			<Row>
				<HorizontalCol>
	        <BodyContent
	          id={id}
					  loadModule={loadModule}
	  				modulename={modulename}
	  				search_results={search_results}
	  				searchResultsChanged={searchResultsChanged}
	        />
	      </HorizontalCol>
	    </Row>
		</Grid>
  );
}

const VerticalLayout = (props) => {
  const { id='', innerRef, loadModule, modulename, search_results=[], searchResultsChanged, user, userChanged } = props;
  return (
    <Grid fluid>
		  <Row>
        <LeftCol sizes={{lg: 1, md: 3, sm: 4, xs: 3}}>
        	<Menubar inverted fluid>
	            <ProfileAvatar
	      				onClick={() => loadModule({modulename: 'Search'})}
	      				user={user}
	      				userChanged={userChanged}
	      				userLoggedOut={() => userChanged(null)}
	      			/>
							<Input
								placeholder="search"
							/>

	          {!!search_results.length &&
	          <SearchListing
	  					items={search_results}
	  					loadModule={loadModule}
	  				/>
	  				}
	          <Modules
	          	vertical={true}
	    				loadModule={loadModule}
	    				activeItem={modulename}
	    				search_results={search_results}
	    			/>
	    		</Menubar>
				</LeftCol>
        <RightCol sizes={{lg:11, md: 9, sm: 8, xs: 9}}>
	        <BodyContent
	          id={id}
					  loadModule={loadModule}
	  				modulename={modulename}
	  				search_results={search_results}
	  				searchResultsChanged={searchResultsChanged}
	        />
	      </RightCol>
      </Row>
    </Grid>
  );
}


export default class ThemeLayoutPanel extends React.Component{

	constructor(props) {
		super(props);
		this.contextRef = createRef()
		this.state = {
			theme: Theme,
			show: {
  		  orientation: 'vertical'
  		}
		};
	}

  getTheme(viewport){
    Theme.viewport = viewport;
    return Theme;
  }




  /**
  * Show panels based user logged in, workstation configured
  *   - User NOT Logged In
  *   - Workstation NOT created
  *   - Show Query UI
  */
	render() {
		const { id='', item={}, modulename, search_results, user, loadModule, searchResultsChanged, userChanged } = this.props;
		const { show } = this.state;
		//console.log('THEME LAYOUT RENDER', this.props);

		if(!user){
      return (
        <AuthPanel
				  userChanged={this.props.userChanged}
        />
      );
    }

		return (
		  <ThemeProvider theme={this.getTheme()}>
		    {show.orientation === 'vertical'
  		  ? <VerticalLayout
  		      id={id}
  		      item={item}
  		      modulename={modulename}
  		      innerRef={this.contextRef}
  		      loadModule={loadModule}
  		      searchResultsChanged={searchResultsChanged}
  		      user={user}
  		      userChanged={userChanged}
  		    />
  		  : <HorizontalLayout
  		      id={id}
  		      item={item}
  		      modulename={modulename}
  		      innerRef={this.contextRef}
  		      loadModule={loadModule}
  		      searchResultsChanged={searchResultsChanged}
  		      user={user}
  		      userChanged={userChanged}
  		    />
  		  }
  		</ThemeProvider>
		);
	}
}
