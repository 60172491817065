import React from 'react';
import BaseModule from './base.jsx';

const MODULE_VIEW = 'dashboard';

export default class BaseModuleDashboard extends React.Component{

  render(){
		return (
  		<React.Suspense fallback={<div>Page is Loading...</div>}>
  		  <BaseModule
  		    view={MODULE_VIEW}
  		    {...this.props}
        />
      </React.Suspense>
		);
	}
}
