import React           from 'react';
import styled          from 'styled-components';
import { Menu, Segment, Sidebar }
                       from 'semantic-ui-react';
import ModuleDashboard from 'base/module/dashboard.jsx';
import ModuleView      from 'base/module/view.jsx';

const Container = styled.div`
  height: 100vh;
  //overflow: hidden;
  scroll: auto;
`;

export default class ThemeContentPanel extends React.Component{

  render(){
    const { id='', item={}, modulename, search_results=[], loadModule } = this.props;
    //console.log('THEME CONTENT RENDER', this.props);
    return (
      <Container>
          { id
            ? <ModuleView
                modulename={modulename}
                id={id}
                item={item}
                loadModule={loadModule}
               />
            : <ModuleDashboard
                item={item}
                modulename={modulename}
                loadModule={loadModule}
               />
          }
      </Container>
    );
  }
}
