var CacheEngine = {
  
  get: function(key){
    //console.log('CACHE::GET', key, localStorage.getItem(key));
    let data = localStorage.getItem(key);
    return (data
      ? JSON.parse(data)
      : null
    );
  },
  set: function(key, value){
    //console.log('CACHE::SET', key, value);
    return localStorage.setItem(key, JSON.stringify(value));
  }
}

export default CacheEngine;